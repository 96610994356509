<script>
	import { defineComponent } from "vue";
	
	var barcodeScanRegExp = /Barcode \((.*)\) \[(.*)\]/

	export default defineComponent( {
		name: "WebSocketBarcode",
		props: {},
		data () {
			return {
				barcode: ''
			}
		},
		mounted () {
			this.$options.sockets.onmessage = event => {
				var barcode = ""
				barcode = event.data.replaceAll("\n","").replaceAll("\r","")
				// console.log(barcode.length + " | " + barcode)
				this.barcode = barcodeScanRegExp.exec(barcode)[1]
				// console.log(barcode)
				this.$emit('scanned', this.barcode)
			}
		},
		beforeUnmount () {
			delete this.$options.sockets.onmessage
			// console.log("web socket on message destroyed")
		},
	});
</script>
