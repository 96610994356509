import { createApp } from 'vue';
import VueMobileDetection from "vue-mobile-detection";
import VueNativeSock from 'vue-native-websocket-vue3';
import App from './App.vue';
import router from './router';
import store from "./store";

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/display.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';

/* Theme variables */
import './theme/variables.css';

const app = createApp(App)
	.use(IonicVue)
	.use(router)
	.use(store)
	.use(VueMobileDetection)
	.use(VueNativeSock, 'ws://127.0.0.1:12345', {
		reconnection: true // (Boolean) whether to reconnect automatically (false)
		// reconnectionAttempts: 5,  (Number) number of reconnection attempts before giving up (Infinity),
		// reconnectionDelay: 3000,  (Number) how long to initially wait before attempting a new (1000)
	})
	;

router.isReady().then(() => {
	app.mount('#app');
});