<template>
	<ion-list class="row row-center">
		<ion-item v-if="enabled" lines="none" class="row" id="video-container">
			<video id="video" :poster="require(`@/assets/flat-camera-icon.png`)" @click="getImage()" autoplay></video>
			<audio id="audio">
				<source :src="require(`@/assets/photo_click.mp3`)" type="audio/mpeg">
			</audio>
		</ion-item>
		<ion-item lines="none" class="row" id="photo-container">
			<img id="photo">
			<ion-item v-if="enabled" id="photo-management" lines="none">
				<ion-button v-if="!isSrcImage" id="save-photo" @click="saveImage()">{{ savePhoto }}</ion-button>
				<ion-button v-if="isSrcImage" id="remove-photo" @click="removeImage()">{{ removePhoto }}</ion-button>
				<ion-button v-if="!isSrcImage" id="discard-photo" @click="discardImage()">{{ discardPhoto }}</ion-button>
			</ion-item>
		</ion-item>
	</ion-list>
	<a id="link"></a>
</template>

<style scoped>
#video, #photo {
	/* object-fit: contain; */
	width: 100%; 
	aspect-ratio: inherit;
}
#video {
	cursor: crosshair;
}
#photo-container {display: none;}
#photo-management {
	position:absolute;
	right: 0px;
	top: 0px;
}
</style>

<script>
import { IonButton, IonItem, IonList } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
	name: "TakePhoto",
	emits: ["saved", "removed", "cameraError"],
	props: {
		savePhoto: { type: String, default: "Salva Foto" },
		removePhoto: { type: String, default: "Rimuovi Foto" },
		discardPhoto: { type: String, default: "Scarta Foto" },
		errorCamera: { type: String, default: "Cannot get Media" },
		enabled: { type: Boolean, default: true },
		srcImage: { /*type: String, */default: null }, //Without using type we can manaage both url and file
	},
	data: () => ({
		video: null,
		photo: null,
		saveButton: null,
		removeButton: null,
		videoDevice: null,
		imageBlob: null,
		videoContainer: null,
		photoContainer: null,
		isSrcImage: null,
	}),
	components: {
		IonList,
		IonItem,
		IonButton,
	},
	mounted() {
		this.initComponents()
		if (this.enabled) this.startCamera()
		if (this.srcImage) this.showPhoto()
	},
	beforeUnmount() {
		this.stopCamera()
	},

	methods: {
		initComponents() {
			this.video = document.querySelector("#video")
			this.photo = document.querySelector("#photo")
			this.saveButton = document.querySelector("#save-photo")
			this.removeButton = document.querySelector("#remove-photo")
			this.videoDevice = undefined
			this.imageBlob = undefined
			this.videoContainer = document.querySelector("#video-container")
			this.photoContainer = document.querySelector("#photo-container")
		},
		gotMedia(mediaStream) {
			// Extract video track.
			this.video.srcObject = mediaStream
			this.videoDevice = mediaStream.getVideoTracks()[0]
		},
		failedToGetMedia() {
			this.$emit('cameraError')
		},
		startCamera() {
			// Ask for permission, if needed, to use Camera
			navigator.mediaDevices.getUserMedia({ audio: false, video: {facingMode: 'environment'} }).then(this.gotMedia).catch(this.failedToGetMedia)
		},
		stopCamera() {
			if (this.videoDevice) this.videoDevice.stop()  // turn off the camera
		},
		processPhoto(blob) {
			this.imageBlob = blob
			this.photo.src = window.URL.createObjectURL(blob)

			var shutter = document.getElementById("audio")
			shutter.play()

			this.videoContainer.style.display = 'none'
			this.photoContainer.style.display = 'block'
			},
		showPhoto() {
			this.isSrcImage = true
			if (typeof this.srcImage == "string")
				this.photo.src = this.srcImage
			else
				this.photo.src = window.URL.createObjectURL(this.srcImage)

			if (this.videoContainer) this.videoContainer.style.display = 'none'
			this.photoContainer.style.display = 'block'
		},
		getImage() {
			// Check if this device supports a picture mode...
			let captureDevice = new ImageCapture(this.videoDevice);
			if (captureDevice) {
				captureDevice.takePhoto().then(this.processPhoto).catch(this.stopCamera)
			}
		},
		saveImage() {
			var imageFile = new File([this.imageBlob], 'Picture.png', { lastModified: new Date().getTime(), type: this.imageBlob.type })
			this.$emit('saved', imageFile)

			this.photoContainer.style.display = 'none'
			this.videoContainer.style.display = 'block'
		},
		removeImage() {
			this.isSrcImage = false
			this.imageBlob = null
			this.photo.src = ''
			this.$emit('removed')

			this.photoContainer.style.display = 'none'
			this.videoContainer.style.display = 'block'
		},
		discardImage() {
			this.imageBlob = null
			this.photo.src = ''

			this.photoContainer.style.display = 'none'
			this.videoContainer.style.display = 'block'
		},
	}
});
</script>
