<template>
	<ion-page>
		<spot-header :title="pageTitle" :canGoHome="true" :showLogo="false" @pressedGoHome="$router.push('/home')"></spot-header>
		<ion-header v-if="remainingItems>0 && !isClosed">
			<ion-toolbar>
				<spot-button
					fill="outline"
					size="default"
					color="primary"
					icon="bagAdd"
					:enabled="remainingItems>0  && !isClosed"
					:expand="null"
					text="Aggiungi Oggetto"
					@clicked="addNew()">
				</spot-button>
			</ion-toolbar>
		</ion-header>
		<ion-content :fullscreen="true">
			<div class="results" v-if="showResults">
				<div class="package-title" v-if="packageCode">{{ labelPackage }} {{ packageCode }}</div>
				<div class="group-title">
					<ion-label v-if="this.tablesData.project && this.tablesData.project.receiving == true">{{ labelReceivingText }} ({{ receiptsFound.length }} / {{ tablesData.project.expected_project_rows_count }})</ion-label>
				</div>
				<ion-item v-for="item in receiptsFound" :key="item.id">
					<ion-label>{{item.code}}</ion-label>
					<ion-icon v-if="item.closed" :icon="bagCheck"></ion-icon>
					<spot-button fill="solid" size="default" color="primary" icon="logInOutline" :expand="null" :text="buttonOpenText" @clicked="openReceipt(item.id)">
					</spot-button>
				</ion-item>
				<div class="group-title">
					<ion-label v-if="this.tablesData.project && this.tablesData.project.shipping == true">{{ labelShippingText }} ({{ shipmentsFound.length }} / {{ tablesData.project.expected_project_rows_count }})</ion-label>
				</div>
				<ion-item v-for="item in shipmentsFound" :key="item.id">
					<ion-label>{{item.code}}</ion-label>
					<ion-icon v-if="item.closed" :icon="bagCheck"></ion-icon>
					<spot-button fill="solid" size="default" color="primary" icon="logOutOutline" :expand="null" :text="buttonOpenText" @clicked="openShipment(item.id)">
					</spot-button>
				</ion-item>
			</div>
			<div id="debugArea" style="color:red;" v-if="settings.debug && tablesData.project">
				<div>Test Area</div>
				Current Project: {{ tablesData.project.id }}<br>
			</div>
		</ion-content>
		<ion-footer class="finalize" v-if="remainingItems==0  && !isClosed && (receiptsFound.length > 0 || shipmentsFound.length > 0)">
			<!--<spot-button
				v-if="remainingItems=0 && !isClosed && (receiptsFound.length > 0 || shipmentsFound.length > 0)"
				fill="outline"
				size="small"
				color="primary"
				icon="downloadOutline"
				:enabled="remainingItems>0  && !isClosed"
				:expand="null"
				@clicked="finalizeAll()">
				{{ receiptsFound.length > 0 ? labelWithdrawText : (shipmentsFound.length > 0 ? labelDeliveryText : null) }}
			</spot-button>-->
			<spot-button
				fill="solid"
				size="default"
				color="primary"
				icon="downloadOutline"
				:enabled="remainingItems==0  && !isClosed"
				:expand="null"
				:text="receiptsFound.length > 0 ? labelWithdrawText : (shipmentsFound.length > 0 ? labelDeliveryText : null)"
				@clicked="finalizeAll()">
			</spot-button>
		</ion-footer>
		<spot-footer></spot-footer>
	</ion-page>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style> <!--<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>-->
<style scoped>
	ion-label {
		font-weight:bold;
	}
	.results {
		margin-top: .5em;
	}
	ion-item {
		margin-left: .5em;
		margin-right: .5em;
	}
	.package-title {
		font-weight: bold;
		text-align: center;
	}
	.group-title {
		font-weight: bold;
		margin-left: 3em;
		margin-top: 1em;
	}
	.finalize {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		background-color: white;
	}
	/*
	.finalize:deep(*) {
		margin-top: 0em;
	}
	*/
</style>

<script>
import { IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonPage, IonToolbar } from "@ionic/vue";
import { bagAdd, bagCheck, downloadOutline, logInOutline, logOutOutline } from "ionicons/icons";
import { defineComponent } from "vue";
import SpotButton from "../components/SpotButton.vue";
import SpotFooter from "../components/SpotFooter.vue";
import SpotHeader from "../components/SpotHeader.vue";
import { checkUserSession, settings, showMsgWarning } from "../plugins/common.js";

	export default defineComponent({
		name: "RevisionMultiple",
		props: {
			pageTitle: { type: String, default: "Ritiri/Consegne Multiple" },
			buttonOpenText: { type: String, default: "Apri" },
			labelReceivingText: { type: String, default: "RICEZIONI" },
			labelWithdrawText: { type: String, default: "Consegna tutti" },
			labelShippingText: { type: String, default: "SPEDIZIONI" },
			labelDeliveryText: { type: String, default: "Spedisci tutti" },
			labelfinalize: { type: String, default: "Procedi" },
			messageTitle: { type: String, default: "RICERCA RICEZIONI/SPEDIZIONI" },
			labelPackage: { type: String, default: "Oggetti in:" },
			warningUpdateMessage: { type: String, default: "Non ci sono articoli da movimentare" },
			errorSearchMessage: { type: String, default: "Errore durante la ricerca" },
		},
		components: {
			IonPage,
			IonContent,
			IonHeader,
			IonToolbar,
			IonItem,
			IonLabel,
			IonIcon,
			IonFooter,
			SpotHeader,
			SpotButton,
			SpotFooter,
		},
		data: () => ({
			isActive: null,
			isClosed: null,
			showResults: false,
			packageCode: null,
			openedRowsCount: 0,
			tablesData: {
				project: null,
				projectRows: [],
			},
			logInOutline,
			logOutOutline,
			bagAdd,
			bagCheck,
			downloadOutline,
			settings,
			checkUserSession,
			showMsgWarning,
		}),
		computed: {
			receiptsFound() {
				//TODO:forse meglio questo return this.tablesData.project.project_rows_count
				return this.tablesData.projectRows.filter(function (projectRow) {
					return (projectRow.project.receiving == true)
				})
			},
			shipmentsFound() {
				//TODO:forse meglio questo return this.project.project_rows_count
				return this.tablesData.projectRows.filter(function (projectRow) {
					return (projectRow.project.shipping == true)
				})
			},
			remainingItems() {
				if (this.tablesData.project) return this.tablesData.project.expected_project_rows_count - this.tablesData.project.project_rows_count
				else return 0
			},
			allProjectRows() {
				return {
					params: {
						'q[project_id_eq]': this.$route.params.id,
					}
				}
			},
			allOpenedProjectRows() {
				return {
					params: {
						'q[project_id_eq]': this.$route.params.id,
						'q[closed_eq]': false, //to show only not closed
						'[count]': true,
					}
				}
			},
		},
		async ionViewWillEnter() {
			await this.getAllTablesData();
			this.setDefault();
			await this.getProjectRows();
			this.isActive=true;
		},
		ionViewWillLeave() {
			this.isActive=false;
		},
		methods: {
			//Settings
			//Database
			async searchData(tableName, filters) {
				let data = await this.$store.dispatch("tables/querySearch", { model: tableName, data: filters })
				return data;
			},
			async readData(tableName, recordId) {
				let data = await this.$store.dispatch("tables/read", { model: tableName, id: recordId })
				return data;
			},
			async getAllTablesData() {
				await this.getCurrentProject();
			},
			async getCurrentProject() {
				if (this.$route.params.id) {
					await this.readData("projects", this.$route.params.id)
					.then(data => {
						this.tablesData.project = data;
						this.isClosed=(this.tablesData.project.closed) ? true : false;
					})
					.catch(error => {
						this.checkUserSession(error, "warning");
					})
				}
			},
			async searchRecords(currSearch) {
				this.tablesData.projectRows = [];
				await this.searchData("project_rows", currSearch)
				.then(data => {
					this.tablesData.projectRows = data;
					this.showResults = true;
				})
				.catch(error => {
					this.checkUserSession(error, "warning");
					this.showMsgError(this.messageTitle, this.errorSearchMessage);
					this.showResults = false;
				})
			},
			async getOpenedProjectRows() {
				if (this.$route.params.id) {
					await this.searchOpenedProjectRows(this.allOpenedProjectRows);
				}
			},
			async searchOpenedProjectRows(currSearch) {
				this.openedRowsCount = 0;
				await this.searchData("project_rows", currSearch)
				.then(data => {
					this.openedRowsCount = data.count;
				})
				.catch(error => {
					this.checkUserSession(error, "warning");
				})
			},
			//Actions
			async getProjectRows() {
				this.showResults=true;
				if (this.$route.params.id) {
					this.packageCode=this.$route.query.code;
					await this.searchRecords(this.allProjectRows);
				}
				else {
					this.packageCode=null;
					this.showResults=false;
				}
			},
			openReceipt(movementId) { //TODO: da rivedere meglio perchè non dovrebbe punate qui
						this.$router.push(`/finalize/receipt/` + movementId + `?multipack=true`);
			},
			openShipment(movementId) { //TODO: da rivedere meglio perchè non dovrebbe punate qui
						this.$router.push(`/finalize/shipment/` + movementId + `?multipack=true`);
			},
			setDefault() {
				//Do Nothing
			},
			async finalizeAll() {
				await this.getOpenedProjectRows();
				if (this.openedRowsCount>0) {
					if (this.tablesData.project.receiving == true) this.$router.push(`/finalize/receipt?header_id=${this.$route.params.id}` + `&multipack=true`); // `/finalize/receipt?header_id=` + this.tablesData.project.id + `&multipack=true`
					if (this.tablesData.project.shipping == true) this.$router.push(`/finalize/shipment?header_id=${this.$route.params.id}` + `&multipack=true`); // `/finalize/shipment?header_id=` + this.tablesData.project.id + `&multipack=true`
				}
				else {
					this.showMsgWarning(
						this.messageTitle,
						this.warningUpdateMessage);
				}
			},
			addNew() {
				if (this.tablesData.project.receiving == true) this.$router.push(`/insert/receipt?header_id=${this.$route.params.id}` + `&multipack=true`); // `/insert/receipt?header_id=` + this.tablesData.project.id + `&multipack=true`
				if (this.tablesData.project.shipping == true) this.$router.push(`/insert/shipment?header_id=${this.$route.params.id}` + `&multipack=true`); // `/insert/shipment?header_id=` + this.tablesData.project.id + `&multipack=true`
			}
		},
	});
</script>