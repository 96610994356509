<template>
	<ion-page>
	</ion-page>
</template>

<style scoped>
</style>

<script>
	import { defineComponent } from "vue";
	import {
		IonPage
	} from "@ionic/vue";

	export default defineComponent({
		name: "Library",
		props: {
		},
		components: {
			IonPage,
		},
	});
</script>