import { createRouter, createWebHistory } from '@ionic/vue-router';
import FinalizeReceipt from "../views/FinalizeReceipt.vue";
import FinalizeShipment from "../views/FinalizeShipment.vue";
import Home from '../views/Home.vue';
import Insert from "../views/Insert.vue";
import InsertMultiple from "../views/InsertMultiple.vue";
import InsertReceipt from "../views/InsertReceipt.vue";
import InsertShipment from "../views/InsertShipment.vue";
import Library from "../views/Library.vue";
import Login from "../views/Login.vue";
import RevisionMultiple from "../views/RevisionMultiple.vue";
import Root from "../views/Root.vue";
import Select from "../views/Search.vue";

const routes = [
	{
		path: "/",
		name: "Root",
		component: Root,
	},
/*
	{
		path: '/',
		redirect: '/login',
	},
*/
	{
		path: "/login",
		name: "Login",
		component: Login,
	},
	{
		path: '/home',
		name: 'Home',
		component: Home,
	},
	{
		path: '/insert',
		name: 'Insert',
		component: Insert,
	},
	{
		path: '/insert/receipt',
		name: 'InsertReceipt',
		component: InsertReceipt,
	},
	{
		path: '/insert/receipt/:id',
		name: 'InsertReceiptId',
		component: InsertReceipt,
	},
	{
		path: '/insert/shipment',
		name: 'InsertShipment',
		component: InsertShipment,
	},
	{
		path: '/insert/shipment/:id',
		name: 'InsertShipmentId',
		component: InsertShipment,
	},
{
	path: "/finalize/receipt",
	name: "FinalizeReceip",
	component: FinalizeReceipt,
},
{
		path: "/finalize/receipt/:id",
		name: "FinalizeReceiptId",
		component: FinalizeReceipt,
	},
	{
		path: "/finalize/shipment",
		name: "FinalizeShipment",
		component: FinalizeShipment,
	},
	{
		path: "/finalize/shipment/:id",
		name: "FinalizeShipmentId",
		component: FinalizeShipment,
	},
	{
		path: '/insert/multiple',
		name: 'InsertMultiple',
		component: InsertMultiple,
	},
	{
		path: '/revision/:id',
		name: 'RevisionProjectId',
		component: RevisionMultiple,
	},
	{
		path: '/search',
		name: 'Search',
		component: Select,
	},
	{
		path: '/search/:id',
		name: 'SearchProjectId',
		component: Select,
	},
	{
		path: "/library",
		name: "Library",
		component: Library,
	},
	{
		path: "/library/:table",
		name: "LibraryTable",
		component: Library,
	},
	{
		path: "/library/:table/:id",
		name: "LibraryTableId",
		component: Library,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
