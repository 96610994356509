<template>
	<ion-page>
		<spot-header :title="pageTitle" :canGoHome="true" :showLogo="false" @pressedGoHome="$router.push('/home')"></spot-header>
		<ion-content :fullscreen="true">
			<div id="fieldsContainer" v-if="isActive">
				<div v-if="currField.id==0"> <!-- currField.name == 'movementType' -->
					<spot-select
						:ref="currField.name"
						:label="currField.text"
						:allignLabel="currField.allignLabel"
						:required="currField.required"
						:placeholder="getPlaceHolder()"
						:enabled="!isClosed"
						:value="currField.value"
						:options="tablesData.movementTypes"
						:optionField="currField.refField"
						:canAddItem="currField.canAddItem"
						:canRefreshList="currField.canRefresh"
						:canUnlock="currField.canUnlock"
						selectedLabel="Selezionato"
						deselectLabel="rimuovi"
						selectLabel="seleziona"
						@selected="detectSelection"
						@unselected="detectUnselection">
					</spot-select>
				</div>
				<div v-if="currField.id==1"> <!-- currField.name == 'movementPackage' -->
					<spot-select
						:ref="currField.name"
						:label="currField.text"
						:allignLabel="currField.allignLabel"
						:required="currField.required"
						:placeholder="getPlaceHolder()"
						:enabled="!isClosed"
						:value="currField.value"
						:options="tablesData.movementPackages"
						:optionField="currField.refField"
						:canAddItem="currField.canAddItem"
						:canRefreshList="currField.canRefresh"
						:canUnlock="currField.canUnlock"
						selectedLabel="Selezionato"
						deselectLabel="rimuovi"
						selectLabel="seleziona"
						@selected="detectSelection"
						@unselected="detectUnselection">
					</spot-select>
				</div>
				<div v-if="currField.id==2"> <!-- currField.name == 'movementHeader' -->
					<div class="items-label">
						<ion-label>{{ currField.text }}</ion-label>
						<span v-if="currField.required">*</span>
					</div>
					<div class="items-data">
						<ion-button fill="outline" size="large" @click="createAndPrint()">
							{{ !currField.value ? (canPrint ? labelSave + labelAnd + labelPrint : labelSave) : (canPrint ? labelReprint : null) }}
							<ion-icon v-if="!currField.value" slot="icon-only" :icon="save" size="large"></ion-icon>
							<ion-icon v-if="canPrint" slot="icon-only" :icon="print" size="large"></ion-icon>
						</ion-button>
					</div>
				</div>
			</div>
			<div id="actionsContainer">
				<spot-button
					v-if="isVisibleButton(currField.canGoBack)"
					fill="clear"
					size="large"
					color="primary"
					icon="chevronBackCircleOutline"
					:enabled="currField.canGoBack && !fields.list[this.fields.list.length-1].value"
					:expand="null"
					:text=null
					@clicked="goBack()">
				</spot-button>
				<spot-button
					v-if="isVisibleButton(currField.canGoAhead)"
					fill="clear"
					size="large"
					color="primary"
					icon="chevronForwardCircleOutline"
					:enabled="!isDisableButton(currField.canGoAhead)"
					:expand="null"
					:text=null
					@clicked="goAhead()">
				</spot-button>
			</div>
			<div id="debugArea" style="color:red;" v-if="settings.debug">
				<div>Test Area</div>
				Current Field: {{ currField }}<br>
				<div v-for="field in fields.list" :key="field.id">
					{{ field.name }} = {{ field.value }}
				</div>
			</div>
		</ion-content>
		<spot-footer></spot-footer>
	</ion-page>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style> <!--<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>-->
<style scoped>
	ion-label {
		font-weight:bold;
	}
	.items-label {
		margin-top: .5em;
		margin-left: 1em;
	}
	.items-data {
		display: flex; /*inline-block;*/
		flex-direction: row;
		vertical-align: baseline;
		justify-content: space-between;
		text-align: left;
		margin-left: .5em;
		margin-right: .5em;
	}
</style>

<script>
import { IonButton, IonContent, IonIcon, IonLabel, IonPage } from "@ionic/vue";
import { chevronBackCircleOutline, chevronForwardCircleOutline, print, save } from "ionicons/icons";
import { defineComponent } from "vue";
import SpotButton from "../components/SpotButton.vue";
import SpotFooter from "../components/SpotFooter.vue";
import SpotHeader from "../components/SpotHeader.vue";
import SpotSelect from "../components/SpotSelect.vue";
import { checkUserSession, confirmYesNo, getUserInfo, settings, showMsgError, showMsgInfo, showMsgWarning } from "../plugins/common.js";

	export default defineComponent({
		name: "Insert",
		props: {
			pageTitle: { type: String, default: "Inserisci nuova registrazione" },
			labelPrint: { type: String, default: "Stampa" },
			labelReprint: { type: String, default: "Ristampa" },
			labelSave: { type: String, default: "Salva" },
			labelAnd: { type: String, default: " e " },
			labelContinue: { type: String, default: "Prosegui" },
			messageTitleInsert: { type: String, default: "Creazione Sovracollo" },
			messageTitlePrint: { type: String, default: "Stampa Etichetta" },
			successSaveMessage: { type: String, default: "Sovracollo creato" },
			errorSaveMessage: { type: String, default: "Errore durante la creazione del sovracollo" },
			successPrintMessage: { type: String, default: "Stampa sovracollo avvenuta correttamente. Etichetta: " },
			errorPrintMessage: { type: String, default: "Errore durante la stampa del sovracollo" },
			warningPrinterOffline: { type: String, default: "La stampante non è raggiungibile; l'etichetta non è stata stampata" },
			warningPrintConfirmMessage: { type: String, default: "Confermi di procedere lo stesso anche se non è stata assegnata una stampante (l'etichetta non verrà stampata)?" },
			unselected: { type: String, default: "Nessuno" },
		},
		components: {
			IonPage,
			IonButton,
			IonIcon,
			IonContent,
			IonLabel,
			SpotHeader,
			SpotFooter,
			SpotSelect,
			SpotButton,
		},
		data: () => ({
			isActive: null,
			isClosed: null,
			header_id: null,
			header_code: null,
			printer_id: null,
			fields: {
				currentId: 0,
				list: [
					{ id: 0, name: 'movementType', text: 'Tipo Movimento', allignLabel: false, placeholder: 'Seleziona Tipo Movimento', inputType: "combo", defaultValue: null, value: null, required: true, refField: 'text', canAddItem: false, canSkip: false, canGoBack: false, canGoAhead: true, canSave: false, canRefresh: false, canUnlock: false },
					{ id: 1, name: 'movementPackage', text: 'Imballo', allignLabel: false, placeholder: 'Seleziona Imballo', inputType: "combo", defaultValue: null, value: null, required: true, refField: 'text', canAddItem: false, canSkip: false, canGoBack: true, canGoAhead: true, canSave: false, canRefresh: false, canUnlock: false },
					{ id: 2, name: 'movementHeader', text: 'Salva e Stampa Sovracollo', allignLabel: false, placeholder: 'Salva e Stampa', inputType: "button", defaultValue: null, value: null, required: true/*false*/, canAddItem: false, canSkip: false, canGoBack: true, canGoAhead: true, canSave: false, canRefresh: false, canUnlock: false },
				],
			},
			tablesData: {
				movementTypes: [
					{ id: 0, name: 'Receipt', text: 'RICEZIONE', value: 'Receipt'},
					{ id: 1, name: 'Shipment', text: 'SPEDIZIONE', value: 'Shipment'},
				],
				movementPackages: [
					{ id: 0, name: 'PACC', text: 'PACCO SINGOLO', value: 'PACC'},
					{ id: 1, name: 'CASS', text: 'CASSA', value: 'CASS'},
					{ id: 2, name: 'BANC', text: 'BANCALE', value: 'BANC'},
				],
			},
			print,
			save,
			chevronBackCircleOutline,
			chevronForwardCircleOutline,
			settings,
			getUserInfo,
			confirmYesNo,
			checkUserSession,
			showMsgInfo,
			showMsgWarning,
			showMsgError,
		}),
		computed: {
			currField() {
				return this.fields.list[this.fields.currentId]
			},
			canPrint() {
				return (this.settings.printerManagement && (this.printer_id != null) && (this.fields.list[1].value.id !== 0))
			}
		},
		async ionViewWillEnter() {
			await this.setDefault();
			this.getPrinter();
			this.isActive=true;
		},
		ionViewWillLeave() {
			this.isActive=false;
		},
		methods: {
			//Settings
			getPlaceHolder() {
				return ((this.settings.showFieldsPlaceholder) ? (this.currField.required ? this.currField.placeholder : this.unselected ) : '')
			},
			isDisableButton(ability) {
				if (this.settings.fieldsCheckOnButtons && ability) {
					return (this.currField.required && !this.currField.value) ?  true : false
				}
				else {
					return !ability
				}
			},
			isVisibleButton(ability) {
				return (this.settings.hideUnneededButtons) ? ability : true
			},
			//Database
			async searchData(tableName, filters) {
				let data = await this.$store.dispatch("tables/querySearch", { model: tableName, data: filters })
				return data;
			},
			async getData(tableName) {
				let data = await this.$store.dispatch("tables/fetch", { model: tableName })
				return data;
			},
			async readData(tableName, recordId) {
				let data = await this.$store.dispatch("tables/read", { model: tableName, id: recordId })
				return data;
			},
			async writeData(tableName, record) {
				let data = await this.$store.dispatch("tables/create", { model: tableName, data: record })
				return data;
			},
			getPrinter() {
				if (this.settings.printerManagement) {
					let userInfo = this.getUserInfo();
					if (!userInfo || !userInfo.plant || !userInfo.plant.printer_id) {
						let currSearch = {
							params: {
								'q[default_eq]': true,
							}
						}
						this.searchData("printers", currSearch)
						.then(data => {
							if (data.length>0) {
								this.printer_id = data[0].id
							}
							else {
								this.printer_id = null;
								this.confirmYesNo(this.messageTitlePrint, this.warningPrintConfirmMessage)
								.then((data) => {
									if (data == false) this.$router.push("/home");
								})
							}
						})
						.catch(error => { this.printer_id = null; this.checkUserSession(error, "warning"); });
					}
					else {
						this.printer_id = userInfo.plant.printer_id;
					}
				}
				else {
						this.printer_id = null;
				}
			},
			prepareNewRecord() {
				let myRecord = {
					project: {
						name: (this.fields.list[1].value) ? this.fields.list[1].value.value : null,
						receiving: (this.fields.list[0].value.id == 0) ? true : false,
						shipping: (this.fields.list[0].value.id == 1) ? true : false,
					}
				};
				return myRecord;
			},
			saveRecord() {
				this.writeData("projects", this.prepareNewRecord())
				.then(data => {
					this.header_id = data.id;
					this.header_code = data.code;
					this.currField.value=true;
					if (this.canPrint && this.printer_id != null) {
						this.readData("printers", this.printer_id)
						.then(data => {
							if (data.is_online == true) {
								this.getData(`printers/` + this.printer_id + `?do=print_single_barcode` + `&barcode=` + this.header_code + `&direction= ` + `&employee= `)
								.then(() => {
									this.showMsgInfo(
										this.messageTitleInsert,
										this.successSaveMessage + "<br><br>" + this.successPrintMessage + "&nbsp" + this.header_code);
								})
								.catch(error => {
									this.checkUserSession(error, "warning");
									this.showMsgError(this.messageTitlePrint, this.errorPrintMessage);
								})
							}
							else {
								this.showMsgWarning(
										this.messageTitleInsert,
										this.successSaveMessage + "<br><br>" + this.warningPrinterOffline);
							}
						})
						.catch(error => {
							this.checkUserSession(error, "warning");
							this.showMsgError(this.messageTitlePrint, this.errorPrintMessage);
						})
					}
					else {
						this.showMsgInfo(
							this.messageTitleInsert,
							this.successSaveMessage);
						this.goAhead();
					}
				})
				.catch(error => {
					this.checkUserSession(error, "warning");
					this.showMsgError(this.messageTitleInsert, this.errorSaveMessage);
				})
			},
			//Actions
			async setDefault() {
				await this.getData("printers")
				.then(data => { (data.length > 0) ? this.settings.printerManagement = true : this.settings.printerManagement = false ; })
				.catch(error => { this.checkUserSession(error, "warning"); });
				this.fields.currentId = 0;
				this.fields.list.forEach(element => element.value = element.defaultValue);
				this.header_id = null;
				this.header_code = null;
			},
			detectSelection(value) {
				this.currField.value = value;
				if (this.settings.nextFieldOnComboSelect) setTimeout(() => this.goAhead(), 0);
			},
			detectUnselection() {
				this.currField.value = this.currField.defaultValue;
			},
			goBack() {
				if (this.fields.currentId > 0 ) this.fields.currentId-=1;
			},
			goAhead() {
				if (this.fields.currentId < (this.fields.list.length-1) ) this.fields.currentId+=1
				else this.executeLastFieldAction()
			},
			createAndPrint() {
				if (!this.currField.value) {
					this.saveRecord();
				}
				else {
					if (this.canPrint && this.printer_id != null) {
						this.readData("printers", this.printer_id)
						.then(data => {
							if (data.is_online == true) {
								this.getData(`printers/` + this.printer_id + `?do=print_single_barcode` + `&barcode=` + this.header_code + `&direction= ` + `&employee= `)
								.then(() => {
									this.showMsgInfo(
										this.messageTitlePrint,
										this.successPrintMessage + "&nbsp" + this.header_code);
								})
								.catch(error => {
									this.checkUserSession(error, "warning");
									this.showMsgError(this.messageTitlePrint, this.errorPrintMessage);
								})
							}
							else {
								this.showMsgWarning(
										this.messageTitlePrint,
										this.warningPrinterOffline);
							}
						})
						.catch(error => {
							this.checkUserSession(error, "warning");
							this.showMsgError(this.messageTitlePrint, this.errorPrintMessage);
						})
					}
				}
			},
			executeLastFieldAction() {
				if (this.settings.fieldsCheckOnButtons == true) {
					let multi = (this.fields.list[1].value.id !== 0) ? true : false
					switch (this.fields.list[0].value.value) {
						case 'Receipt':
							this.$router.push(`/insert/receipt?header_id=` + this.header_id + `&multiple=` + multi);
							break;
						case 'Shipment':
							this.$router.push(`/insert/shipment?header_id=` + this.header_id + `&multiple=` + multi);
							break;
						default:
							//TODO: aggiungi checkUserSession (ma non dovrebbe mai arrivarci)
					}
				}
			},
			addItem(tableName) {
				if (tableName) {
				//TODO: Implementa la funzionalità generica AddItem
				}
			},
		},
	});
</script>